import { render, staticRenderFns } from "./mySafeguard.vue?vue&type=template&id=7f2a2dce"
import script from "./mySafeguard.vue?vue&type=script&lang=js"
export * from "./mySafeguard.vue?vue&type=script&lang=js"
import style0 from "./mySafeguard.vue?vue&type=style&index=0&id=7f2a2dce&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports