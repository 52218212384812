<template>
  <div class="my-safeguard">
    <div class="safeguard_list">
      <van-list
        v-model="loading"
        :finished="dataList.length >= totalRows"
        :finished-text="dataList.length > 0 ? '没有更多了' : '暂无记录'"
        @load="getList"
      >
        <div class="safeguard_item" v-for="item in dataList" :key="item.id">
          <div class="item_top">
            <div class="item_title">{{item.product}}</div>
            <div class="item_status">{{item.remark}}</div>
          </div>
          <div class="item_content">
            <div class="item_info">保单号：{{item.insuranceNo}}</div>
            <div class="item_info">保障期限：{{item.startDate}}至{{item.endDate}}</div>
            <div class="item_info">被保险人：{{item.name}}</div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getInsuranceInfo } from "@/utils/api";
export default {
  name: "mySafeguard",
  data() {
    return {
      loading: false,
      dataList: [],
      totalRows: 0,
      pageIndex: 0,
      pageSize: 10,
    };
  },
  created() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.dataList = [];
      this.totalRows = 0;
      this.pageIndex = 0;
      this.getList();
    },
    getList() {
      const index = this.pageIndex + 1
      getInsuranceInfo({
        pageIndex: index,
        pageSize: this.pageSize,
      }).then((res) => {
        console.log(res);
        let { list = [], totalRecord = 0, pageIndex = 0 } = res.data.data;
        this.dataList = this.dataList.concat(list);
        this.totalRows = totalRecord;
        this.pageIndex = pageIndex;
      });
    },
  },
};
</script>

<style lang="less">
.my-safeguard {
  background: #f7f8f9;
  height: 100%;
  .safeguard_list {
    padding: 0.15rem;
    height: 100%;
    overflow-y: auto;
    .safeguard_item {
      margin-bottom: 0.2rem;
      background: #ffffff;
      box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.07);
      border-radius: 0.16rem;
      .item_top {
        height: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.3rem;
        border-bottom: solid 1px #e5e5e5;
        .item_title {
          font-size: 0.32rem;
          color: #333333;
        }
        .item_status {
          font-size: 0.24rem;
          color: #999999;
        }
      }
      .item_content {
        padding: 0.3rem;
        .item_info {
          font-size: 0.28rem;
          color: #666666;
          line-height: 0.6rem;
        }
      }
    }
  }
}
</style>
